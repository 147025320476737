import * as React from "react";
import HeadsetComponent from "./components/Headset";
import { createRoot } from "react-dom/client";
import WelcomeHero from "./components/WelcomeHero";
import EquirectangularBackground from "./components/EquirectangularBackground";
import Padding from "./components/subcomponents/Padding";

const AOS = require("aos");
import "aos/dist/aos.css";
import TextTransition, { presets } from "react-text-transition";

import VanillaTilt from "vanilla-tilt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faScrewdriverWrench,
  faHandHoldingHeart,
  faTriangleExclamation,
  faCode,
  faCircleCheck,
  faCircleXmark,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import {
  faDiscord,
  faGithub,
  faLinkedin,
  faRaspberryPi,
  faUnity,
} from "@fortawesome/free-brands-svg-icons";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import "./styles.scss";
import {
  BothSub,
  InfoSub,
  RemoteSub,
  StandaloneSub,
} from "./components/subcomponents/InfoSubComponents";
import Collapsible from "react-collapsible";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { CopyBlock, github } from "react-code-blocks";

const ChangingText = ({
  content,
  isRight = false,
  duration = 3000,
}: {
  content: Array<string>;
  isRight?: boolean;
  duration?: number;
}) => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    let intervalId: NodeJS.Timer | null = null;

    function handleVisibilityChange() {
      if (document.visibilityState !== "visible" && intervalId) {
        clearInterval(intervalId);
      } else {
        intervalId = setInterval(
          () => setIndex((prevIndex) => (prevIndex + 1) % content.length),
          duration // every 3 seconds
        );
      }
    }

    window.addEventListener("visibilitychange", handleVisibilityChange);

    intervalId = setInterval(
      () => setIndex((prevIndex) => (prevIndex + 1) % content.length),
      duration // every 3 seconds
    );

    return () => {
      clearTimeout(intervalId);
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [content]);

  const classes = `text-transition ${isRight ? "has-text-right" : ""}`;
  const dir = isRight ? "up" : "down";
  return (
    <TextTransition
      inline
      springConfig={presets.slow}
      className={classes}
      direction={dir}
    >
      {content[index]}
    </TextTransition>
  );
};
const Slogan = ({ content }: { content: Array<string> }) => {
  return (
    <div>
      <Padding paddingTop={10}></Padding>
      <ChangingText content={content} isRight={false} />
      <Padding paddingTop={10}></Padding>
    </div>
  );
};

const showLoader = () => {
  const loader = document.querySelector(".loader-wrapper");
  return loader.classList.remove("loader-wrapper--hide");
};

const hideLoader = () => {
  const loader = document.querySelector(".loader-wrapper");
  return loader.classList.add("loader-wrapper--hide");
};

class App extends React.Component<any, any> {
  private welcomeHeroRef: React.RefObject<HTMLElement>;
  private equirectangularRef: React.RefObject<HTMLElement>;

  private hasScrolled: boolean = false;
  private intervalId: NodeJS.Timeout | null = null;
  sectionPadding: number;

  constructor(props: any) {
    super(props);
    this.state = {
      opacity: 1,
      width: 100,
      tabIndex: 0,
    };
    this.welcomeHeroRef = React.createRef();
    this.equirectangularRef = React.createRef();

    this.sectionPadding = 5;
  }
  listenScrollEvent = () => {
    this.hasScrolled = true;
    const scrollPosition = window.scrollY;
    const welcomeHeroNode = this.welcomeHeroRef.current;

    if (isBrowser) {
      if (welcomeHeroNode) {
        const welcomeHeroTop = welcomeHeroNode.offsetTop;
        const windowHeight = window.innerHeight;

        // Calculate the distance of the WelcomeHero from the bottom of the viewport
        const diff = welcomeHeroTop - scrollPosition;
        // Calculate the ratio of how much of the WelcomeHero is in the viewport
        const newOpacity = 1 - Math.max(Math.min(1, diff / windowHeight), 0);

        this.setState({
          scrollPosition,
          opacity: newOpacity,
          width: Math.max(80, Math.log2(newOpacity + 1) * 100),
        });
      }
    }
  };

  componentDidMount() {
    this.props.hideLoader();
    AOS.init();
    AOS.refresh();

    const vanilla_tilt_elems = Array.from(
      document.querySelectorAll(".custom-button")
    ) as HTMLElement[];

    VanillaTilt.init(vanilla_tilt_elems, {
      max: 5,
      speed: 150,
      reverse: false,
      easing: "ease-in-out",
      scale: 1.01,
    });

    window.addEventListener("load", () => {
      // this.props.hideLoader();
      if (isBrowser) {
        setTimeout(() => {
          if (!this.hasScrolled) {
            // window.scrollBy(0, 330);
            window.scrollTo({
              top: 330,
              behavior: "smooth",
            });
            this.hasScrolled = true;
          }
        }, 3000);
      }
    });

    window.addEventListener("scroll", this.listenScrollEvent);
  }

  componentWillUnmount() {
    // Make sure to remove the event listener when the component unmounts
    window.removeEventListener("scroll", this.listenScrollEvent);
  }

  shouldComponentUpdate() {
    return true; // Will cause component to never re-render.
  }

  render() {
    return (
      <div>
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
        <BrowserView>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
            }}
          >
            <HeadsetComponent></HeadsetComponent>
          </div>
          <div style={{ height: "100vh" }}></div>
        </BrowserView>
        <div
          style={
            {
              // opacity: Math.max(1, this.state.opacity),
            }
          }
          ref={this.welcomeHeroRef as React.RefObject<HTMLDivElement>}
        >
          <div
            className="container is-fluid"
            style={{
              width: `${this.state.width}%`,
              padding: 0,
              position: "sticky",
              // paddingBottom: "10vh",
            }}
            ref={this.equirectangularRef as React.RefObject<HTMLDivElement>}
          >
            <WelcomeHero
              fill={this.state.opacity}
              top={this.equirectangularRef}
            ></WelcomeHero>
            <div
              style={{
                // height: "100vh",
                // backgroundColor: `rgba(0,0,0, ${this.state.opacity - 0.5})`,
                backdropFilter: "blur(3px)",
                background: "#9e18ff1a",
              }}
            >
              <div
                style={{
                  background: "black",
                }}
              >
                <Padding paddingTop={10}></Padding>
              </div>
              <div className="equirectangular">
                <div
                  data-aos="fade-in"
                  data-aos-offset="-150"
                  data-aos-delay="1000"
                  data-aos-duration="2000"
                  data-aos-once="true"
                >
                  <BrowserView>
                    <EquirectangularBackground></EquirectangularBackground>
                  </BrowserView>
                </div>
                <img
                  data-aos="fade-in"
                  data-aos-offset="-200"
                  data-aos-delay="100"
                  data-aos-duration="500"
                  data-aos-once="true"
                  className="overlay-img"
                  src="/assets/images/sc_wisor_transparent_expanded_cropped.png"
                  alt=""
                />
              </div>

              {/* <Padding paddingTop={5}></Padding> */}

              <div
                className="section content"
                style={{
                  background: "#190f31",
                  paddingTop: "1.5rem",
                  paddingBottom: "1.5rem",
                  marginBottom: 0,
                }}
              >
                <div className="container">
                  <div
                    className="columns is-8"
                    data-aos="fade-down"
                    data-aos-offset="-50"
                    data-aos-delay="100"
                    data-aos-duration="500"
                    data-aos-once="true"
                  >
                    <div className="column">
                      <a href="https://github.com/egemenertugrul/Wisor">
                        <div className="custom-button">
                          <h3 className="button-text">
                            <FontAwesomeIcon icon={faGithub} />
                            &nbsp;GitHub
                          </h3>
                        </div>
                      </a>
                    </div>
                    <div className="column">
                      <a href="/docs">
                        <div className="custom-button">
                          <h3 className="button-text">
                            <FontAwesomeIcon icon={faBook} />
                            &nbsp;Documentation
                          </h3>
                        </div>
                      </a>
                    </div>
                    <div className="column">
                      <a href="https://discord.gg/FHgPtREb6r">
                        <div className="custom-button">
                          <h3 className="button-text">
                            <FontAwesomeIcon icon={faDiscord} />
                            &nbsp;Discord
                          </h3>
                        </div>
                      </a>
                    </div>
                    <div className="column">
                      <a href="https://egemenertugrul.itch.io/openwixr">
                        <div className="custom-button">
                          <h3 className="button-text">
                            <FontAwesomeIcon icon={faHandHoldingHeart} />
                            &nbsp;Support
                          </h3>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section content">
                <div className="container">
                  <div className="custom-container">
                    <div>
                      <div className="title-area">
                        <div className="title">Introduction</div>
                      </div>
                      <div className="text-area">
                        <div className="section">
                          <div className="container">
                            <div className="columns">
                              <div className="column">
                                <p>
                                  <span
                                    style={{
                                      // display: "inline",
                                      height: "1em",
                                      width: "15em",
                                      // display: "inline-block",
                                      // fontWeight: 600,
                                    }}
                                  >
                                    <b>Wisor</b>
                                    ,&nbsp;
                                  </span>
                                  is an open-source XR headset project that aims
                                  to offer an affordable, end-to-end, and
                                  versatile alternative for general-purpose use.
                                </p>
                                <p>
                                  Our mission is to make XR technology
                                  accessible and affordable to everyone,
                                  eliminating the requirement for expensive
                                  hardware while delivering a seamless
                                  experience. Furthermore, we wish to lay a
                                  foundational framework for the development of
                                  wireless and open-source XR headsets,
                                  contributing to the growth of immersive
                                  technologies.
                                </p>
                                <p>
                                  No sign-ups, no vendor lock-ins. Complete
                                  freedom and accessibility for all.
                                </p>
                                <hr />
                                <p>
                                  We designed Wisor to be cost-effective,
                                  ensuring that more people can enjoy XR
                                  experiences. The overall estimated cost of
                                  materials is less than $250. Moreover, due to
                                  the modular design of the hardware and
                                  software, it is straightforward to add or
                                  remove features as needed, tailoring the
                                  headset to suit your specific requirements.
                                </p>
                                <p>
                                  The headset presently functions as an AR
                                  headset, although the design and the
                                  underlying software architecture can be
                                  readily adapted for VR experiences.
                                </p>
                                <p>
                                  We prioritize interoperability in Wisor. The
                                  headset offers both standalone functionality
                                  as well as remote rendering and processing
                                  capabilities. This flexibility allows users to
                                  choose the mode that suits their needs, making
                                  Wisor a highly versatile and adaptable XR
                                  solution.
                                </p>
                                <div className="columns">
                                  <div
                                    className="column"
                                    style={{
                                      marginTop: "0.5em",
                                      marginBottom: "0.5em",
                                    }}
                                  >
                                    <p className="tab">
                                      The standalone mode
                                      <StandaloneSub /> provides fundamental
                                      features including 3
                                      <span title="degrees-of-freedom">
                                        DOF
                                      </span>{" "}
                                      tracking, stereo rendering, spatial{" "}
                                      <span title="user interface">UI</span>,
                                      and input capabilities. The default
                                      standalone application serves as a
                                      fallback in case the remote mode is
                                      disconnected.
                                    </p>
                                    <span
                                      className="has-text-centered"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        columnGap: "0.2em",
                                        height: "1em",
                                        userSelect: "none",
                                      }}
                                    >
                                      <span title="Powered by Python">.py</span>{" "}
                                      <span title="Powered by C">.c</span>{" "}
                                      {" | "}
                                      <FontAwesomeIcon
                                        title="Powered by Raspberry Pi"
                                        icon={faRaspberryPi}
                                      ></FontAwesomeIcon>{" "}
                                      <img
                                        src="/assets/images/raylib_logo.png"
                                        title="Powered by Raylib"
                                      />
                                    </span>
                                  </div>
                                  <div
                                    className="column"
                                    style={{
                                      marginTop: "0.5em",
                                      marginBottom: "0.5em",
                                    }}
                                  >
                                    <p
                                      className="tab"
                                      style={{
                                        borderLeft: "2px #464e9199 solid",
                                      }}
                                    >
                                      The remote mode
                                      <RemoteSub /> works over the network and
                                      enables users to leverage existing
                                      hardware (e.g. PC, smartphone) to offload
                                      computational tasks of the headset. In
                                      addition to improved performance and
                                      visual quality, it grants access to
                                      features like 6
                                      <span title="degrees-of-freedom">
                                        DOF
                                      </span>{" "}
                                      tracking (
                                      <span title="simultaneous localization and mapping">
                                        SLAM
                                      </span>
                                      ), hand-tracking, and more.
                                    </p>
                                    <span
                                      className="has-text-centered"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        columnGap: "0.2em",
                                        height: "1em",
                                        userSelect: "none",
                                      }}
                                    >
                                      <span title="Powered by C#">.cs</span>{" "}
                                      <span title="Powered by C++">.cpp</span>{" "}
                                      {" | "}
                                      <FontAwesomeIcon
                                        title="Powered by Unity"
                                        icon={faUnity}
                                      ></FontAwesomeIcon>
                                      <img
                                        src="/assets/images/gst_logo.png"
                                        title="Powered by GStreamer"
                                        style={{
                                          height: "1em",
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <p>
                                  We welcome all enthusiasts and developers to
                                  join us on our journey of building open-source
                                  XR together!
                                </p>
                                <br />
                                <div className="columns is-centered">
                                  <div className="column is-4-desktop">
                                    <a href="/docs">
                                      <div className="custom-button">
                                        <h3 className="button-text">
                                          <FontAwesomeIcon icon={faRocket} />
                                          &nbsp;Get Started
                                        </h3>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Padding paddingTop={this.sectionPadding}></Padding>
                    <div>
                      <div className="title-area">
                        <div className="title">Hardware</div>
                      </div>
                      <div className="text-area">
                        <div className="section">
                          <div className="container">
                            <div className="columns">
                              <div className="column">
                                <p>
                                  We aimed to cater to{" "}
                                  <span title="do-it-yourself">DIY</span>{" "}
                                  enthusiasts who may not have expert knowledge,
                                  making it easy for them to assemble and get
                                  started. No soldering or PCB printing
                                  required.
                                </p>
                                <p>
                                  There are seven main components of the
                                  hardware design:
                                  <Tabs>
                                    <TabList>
                                      <Tab>Housing</Tab>
                                      <Tab>Display</Tab>
                                      <Tab>Optical Combiner</Tab>
                                      <Tab>Processor</Tab>
                                      <Tab>Camera</Tab>
                                      <Tab>Inertial Measurement Unit</Tab>
                                      <Tab>Power supply</Tab>
                                    </TabList>

                                    <div className="tab-panel-content">
                                      <TabPanel>
                                        <p>
                                          The housing of Wisor is constructed
                                          using 3D printing, enabling us to
                                          create a lightweight and
                                          cost-effective enclosure for the
                                          headset. This approach also ensures
                                          accessibility and facilitates easy
                                          iterations.
                                        </p>
                                      </TabPanel>
                                      <TabPanel>
                                        <p>
                                          The headset uses a single display that
                                          is capable of providing a maximum of
                                          2K resolution at 60 FPS. However, this
                                          performance is contingent on the
                                          specific model of Raspberry Pi in use.
                                        </p>
                                      </TabPanel>
                                      <TabPanel>
                                        <p>
                                          The headset's optics utilize a concave
                                          half-mirror, also known as "bug-eye",
                                          to reflect light from the display to
                                          the user's eyes. This simple method is
                                          also known as the Pepper's ghost
                                          effect.
                                        </p>
                                      </TabPanel>
                                      <TabPanel>
                                        <p>
                                          Wisor utilizes Raspberry Pi(s) as the
                                          only processor component, a
                                          well-established and widely recognized
                                          device within the DIY communities. We
                                          do not enforce users on a specific
                                          model. Currently supported Raspberry
                                          Pi models are as follows:
                                          <ul className="smaller">
                                            <li>Zero: W / 2 W</li>
                                            <li>3: B / B+ / A+</li>
                                            <li>4: B</li>
                                          </ul>
                                        </p>
                                      </TabPanel>
                                      <TabPanel>
                                        <p>
                                          The single camera on the headset,
                                          equipped with a wide-angle lens,
                                          monochrome sensor, and global shutter,
                                          enables us to carry out computer
                                          vision tasks
                                          <BothSub /> and visual/visual-inertial
                                          SLAM.
                                          <RemoteSub />
                                        </p>
                                      </TabPanel>
                                      <TabPanel>
                                        <p>
                                          The inertial measurement unit (IMU) is
                                          employed to precisely measure the
                                          orientation of the headset (3DOF)
                                          <BothSub />
                                          or to supply essential acceleration
                                          and gyroscope data for performing
                                          visual-inertial SLAM (6DOF).
                                          <RemoteSub />
                                        </p>
                                      </TabPanel>
                                      <TabPanel>
                                        <p>
                                          There is no restriction on a specific
                                          power source. One may choose to use
                                          power banks or dedicated batteries for
                                          mobility or opt for chargers,
                                          preferably official ones, for
                                          stationary usage. The only essential
                                          requirement is to ensure sufficient
                                          power for both the display and
                                          Raspberry Pi to prevent any
                                          performance or display issues.
                                        </p>
                                      </TabPanel>
                                    </div>
                                  </Tabs>
                                </p>
                                <p>
                                  Please see the documentation for{" "}
                                  <a
                                    href="/docs/category/hardware"
                                    className="inline-button"
                                  >
                                    <span>
                                      Hardware{" "}
                                      <FontAwesomeIcon
                                        icon={faScrewdriverWrench}
                                      />
                                    </span>
                                  </a>{" "}
                                  for the suggested list of components, 3D
                                  models, printing and assembly.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Padding paddingTop={this.sectionPadding}></Padding>
                    <div>
                      <div className="title-area">
                        <div className="title">Software</div>
                      </div>
                      <div className="text-area">
                        <div className="section">
                          <div className="container">
                            <div className="columns">
                              <div className="column">
                                <p>
                                  We adopted a modular, multi-language and
                                  multi-platform software architecture that
                                  allows both novice and expert developers to
                                  contribute, whether it's through small changes
                                  or significant contributions.
                                </p>
                                <p>
                                  The Raspberry Pi OS, which is based on Debian
                                  Linux, forms the fundamental operating system
                                  that underpins the software of the headset. We
                                  support the latest OS version, Bullseye.
                                  <sup>*</sup> The headset operates in two
                                  primary modes: <b>Standalone</b> and{" "}
                                  <b>Remote</b>. It remains in Standalone mode
                                  until an incoming connection from a remote
                                  client is established. The remote client
                                  currently operates within the Unity
                                  environment, ensuring a smooth development and
                                  adoption process. It leverages Unity's
                                  user-friendly interface, rich features, and
                                  cross-platform build support.<sup>*</sup>
                                </p>
                                <Tabs
                                  onSelect={(index) => {
                                    this.setState({
                                      tabIndex: index,
                                    });
                                  }}
                                >
                                  <TabList>
                                    <Tab
                                      className={`react-tabs__tab ${
                                        this.state.tabIndex === 1
                                          ? "remote"
                                          : ""
                                      }`}
                                    >
                                      Standalone Mode
                                    </Tab>
                                    <Tab
                                      className={`react-tabs__tab ${
                                        this.state.tabIndex === 1
                                          ? "remote"
                                          : ""
                                      }`}
                                    >
                                      Remote Mode
                                    </Tab>
                                  </TabList>
                                  <div
                                    className={`tab-panel-content ${
                                      this.state.tabIndex === 1 ? "remote" : ""
                                    }`}
                                  >
                                    <TabPanel>
                                      <p>
                                        Standalone mode is essentially comprised
                                        of two modules working in tandem:{" "}
                                        <b>Core</b>
                                        <BothSub /> and <b>Renderer</b>
                                        <StandaloneSub />. The interoperation
                                        between the two is done through
                                        bidirectional pipe communication. The
                                        Core module operates continuously
                                        throughout the entire usage of the
                                        headset, while the Renderer module runs
                                        only during standalone mode.
                                      </p>

                                      <p>
                                        <b>Headset:</b>
                                      </p>
                                      <div className="columns">
                                        <div
                                          className="column"
                                          style={{
                                            marginTop: "0.5em",
                                            marginBottom: "0.5em",
                                          }}
                                        >
                                          <p className="tab">
                                            <p>
                                              Core module is written in Python,
                                              providing flexibility,
                                              maintainability, and access to a
                                              vast number of available external
                                              libraries.
                                            </p>
                                            <p>
                                              During Standalone mode, it is
                                              responsible for:
                                              <ul className="smaller">
                                                <li>
                                                  Spawning and handling
                                                  subprocesses:
                                                  <ul>
                                                    <li>Renderer,</li>
                                                    <li>WebSocket Server,</li>
                                                    <li>
                                                      QR scanner for
                                                      establishing new WiFi
                                                      connections
                                                    </li>
                                                  </ul>
                                                </li>
                                                <li>
                                                  Gathering and disseminating
                                                  sensor data{" "}
                                                  <sup>(orientation)</sup> to
                                                  Renderer,
                                                </li>
                                                <li>
                                                  Executing commands received
                                                  from the Renderer,
                                                </li>
                                                <li>
                                                  Awaiting connection from the
                                                  remote client.
                                                  <ul>
                                                    <li>
                                                      Transition to Remote Mode
                                                      if connection is
                                                      established.
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </p>
                                          </p>
                                        </div>
                                        <div
                                          className="column"
                                          style={{
                                            marginTop: "0.5em",
                                            marginBottom: "0.5em",
                                          }}
                                        >
                                          <p className="tab">
                                            <p>
                                              Renderer module runs on top of
                                              Raylib (OpenGL) and is written in
                                              C, ensuring high-performance and
                                              efficiency.
                                            </p>
                                            <p>
                                              It is responsible for:
                                              <ul className="smaller">
                                                <li>
                                                  Building and rendering 3D
                                                  scenes, models, and all other
                                                  features of Raylib,
                                                </li>
                                                <li>
                                                  Basic stereo rendering with
                                                  the appropriate distortion
                                                  shader to counter the lens
                                                  distortion,
                                                </li>
                                                <li>
                                                  Executing the 3DOF rotation of
                                                  the camera using the
                                                  orientation data received from
                                                  the Core module,
                                                </li>
                                                <li>
                                                  Spatial UI for displaying the
                                                  status of the headset, as well
                                                  as supporting simple
                                                  interactions like gaze input,
                                                </li>
                                                <li>
                                                  Sending commands to the Core
                                                  module.
                                                </li>
                                              </ul>
                                            </p>
                                          </p>
                                        </div>
                                      </div>
                                    </TabPanel>
                                    <TabPanel>
                                      <p>
                                        Remote mode allows the headset to
                                        offload computations and rendering to an
                                        external device, through wired/wireless
                                        communication. The remote client can be
                                        any device, such as a PC, smartphone, or
                                        server, that possesses greater
                                        computational power than a Raspberry Pi.
                                        <br />
                                        Please note that, at present, we
                                        primarily offer support for the Windows
                                        OS on the remote client. This preference
                                        is due to our extensive testing and
                                        development efforts focused on ensuring
                                        optimal performance and compatibility
                                        within the Windows environment.
                                        <sup>*</sup>
                                      </p>
                                      <p>
                                        <b>Headset:</b>
                                      </p>
                                      <p>
                                        During Remote mode, only the Core
                                        <BothSub /> module is run with multiple
                                        subprocesses running in parallel.
                                      </p>
                                      <div className="columns">
                                        <div
                                          className="column"
                                          style={{
                                            marginTop: "0.5em",
                                            marginBottom: "0.5em",
                                          }}
                                        >
                                          <p className="tab">
                                            <p>
                                              Core module is written in Python,
                                              providing flexibility,
                                              maintainability, and access to a
                                              vast number of available external
                                              libraries.
                                            </p>
                                            <p>
                                              During Remote mode, it is
                                              responsible for:
                                              <ul className="smaller">
                                                <li>
                                                  Spawning and handling
                                                  subprocesses:
                                                  <ul>
                                                    <li>
                                                      Stream Player,{" "}
                                                      <sup>(omxplayer)</sup>
                                                    </li>
                                                    <li>WebSocket Server,</li>
                                                    <li>
                                                      Camera Streamer{" "}
                                                      <sup>(GStreamer)</sup>
                                                    </li>
                                                  </ul>
                                                </li>
                                                <li>
                                                  Gathering sensor data and
                                                  passing to the WebSocket
                                                  process.
                                                </li>
                                              </ul>
                                            </p>
                                          </p>
                                        </div>
                                        <div
                                          className="column smaller"
                                          style={{
                                            marginTop: "0.5em",
                                            marginBottom: "0.5em",
                                          }}
                                        >
                                          <p className="tab">
                                            <p>
                                              Stream Player subprocess is
                                              essentially a video player, known
                                              as omxplayer, tuned to receive and
                                              display the real-time H264 video
                                              stream from the remote client.
                                            </p>
                                          </p>
                                          <p className="tab">
                                            <p>
                                              WebSocket Server subprocess is now
                                              responsible for sending the
                                              requested type of sensor data
                                              <sup>
                                                (orientation-only or
                                                acceleration & gyroscope)
                                              </sup>{" "}
                                              to the remote client. It also
                                              monitors the heartbeat on
                                              connection and tells the Core to
                                              transition to Standalone Mode in
                                              the event of a lost or
                                              disconnected connection.
                                            </p>
                                          </p>
                                          <p className="tab">
                                            <p>
                                              Camera Streamer subprocess streams
                                              the camera feed to the remote
                                              client in real-time, adhering to
                                              the requested resolution and
                                              framerate.
                                            </p>
                                          </p>
                                        </div>
                                      </div>
                                      <hr />
                                      <p>
                                        <b>Remote Client:</b>
                                      </p>
                                      <p>
                                        The Remote Client runs on the Unity
                                        Editor or a Unity application. It uses
                                        WebSockets protocol to establish
                                        connection with the headset. By design,
                                        it streams the stereo render of the
                                        virtual camera immediately upon
                                        connection through GStreamer. Prior to
                                        establishing the connection, the client
                                        may choose the operation mode that
                                        drives the pose of the camera in the
                                        scene. Once connected to the headset,
                                        the remote client passes the required
                                        message topics to the headset, based on
                                        the selected operation mode.
                                      </p>
                                      <p>
                                        Currently, there are three operation
                                        modes:
                                        <ul className="smaller">
                                          <li>Keyboard & mouse</li>
                                          <li>
                                            Orientation-only <sup>(IMU)</sup>
                                          </li>
                                          <li>
                                            <Collapsible trigger="SLAM (WIP)">
                                              <div
                                                className="tab"
                                                style={{
                                                  borderLeft:
                                                    "hsl(0deg 0% 59.99% / 71.8%) solid 2px",
                                                }}
                                              >
                                                <p>
                                                  To perform SLAM, we integrated
                                                  a state-of-the-art open-source
                                                  project, ORB-SLAM 3, into
                                                  Unity.* This approach,
                                                  however, is not as
                                                  plug-and-play as ARCore or
                                                  ARKit. This is primarily due
                                                  to the variability in camera
                                                  parameters, sensor
                                                  calibrations, and other
                                                  crucial details essential for
                                                  a SLAM system to work,
                                                  particularly with each unique
                                                  assembly of the headset.
                                                  Please be aware that due to
                                                  the{" "}
                                                  <span title="do-it-yourself">
                                                    DIY
                                                  </span>{" "}
                                                  nature of this project, we
                                                  cannot guarantee the
                                                  robustness of the tracking
                                                  performance for each assembled
                                                  headset. You can still use the
                                                  existing calibration files by
                                                  following our guidelines and
                                                  suggested hardware components.
                                                  Nevertheless, we strongly
                                                  recommend conducting your own
                                                  calibrations for both the IMU
                                                  and camera to achieve optimal
                                                  results.
                                                </p>
                                                <p>
                                                  The active tracking modes of
                                                  ORB-SLAM 3 are as follows:
                                                  <ul
                                                    style={{
                                                      listStyleType: "none",
                                                    }}
                                                  >
                                                    <li>
                                                      <FontAwesomeIcon
                                                        icon={faCircleCheck}
                                                      />{" "}
                                                      Monocular{" "}
                                                      <sup>(Camera-only)</sup>
                                                    </li>
                                                    <li>
                                                      <FontAwesomeIcon
                                                        icon={faCircleCheck}
                                                      />{" "}
                                                      Monocular-Inertial{" "}
                                                      <sup>
                                                        (Camera + Acceleration +
                                                        Gyroscope)
                                                      </sup>
                                                    </li>
                                                    <li>
                                                      <FontAwesomeIcon
                                                        icon={faCircleXmark}
                                                      />{" "}
                                                      Stereo{" "}
                                                      <sup>(Stereo Camera)</sup>
                                                    </li>
                                                    <li>
                                                      <FontAwesomeIcon
                                                        icon={faCircleXmark}
                                                      />{" "}
                                                      Stereo-Inertial{" "}
                                                      <sup>
                                                        (Stereo Camera +
                                                        Acceleration +
                                                        Gyroscope)
                                                      </sup>
                                                    </li>
                                                    <li>
                                                      <FontAwesomeIcon
                                                        icon={faCircleXmark}
                                                      />{" "}
                                                      RGB-D{" "}
                                                      <sup>(Depth Camera)</sup>
                                                    </li>
                                                  </ul>
                                                  <p>
                                                    Due to the low-cost hardware
                                                    design, we utilized a
                                                    monocular camera and an IMU,
                                                    resulting in the Stereo,
                                                    Stereo-Inertial, RGB-D
                                                    tracking features remaining
                                                    unused. Note that Monocular
                                                    tracking alone does not
                                                    inherently reflect
                                                    real-world scale and will
                                                    not work as expected in a
                                                    Unity scene.
                                                  </p>
                                                </p>
                                              </div>
                                            </Collapsible>
                                          </li>
                                        </ul>
                                      </p>
                                    </TabPanel>
                                  </div>
                                </Tabs>
                                <p>
                                  For detailed information, visit{" "}
                                  <a
                                    href="/docs/category/software"
                                    className="inline-button"
                                  >
                                    <span>
                                      Software <FontAwesomeIcon icon={faCode} />
                                    </span>
                                  </a>{" "}
                                  section.
                                </p>
                                <p>
                                  <sup>*</sup>Please see{" "}
                                  <a
                                    href="/docs/limitations-future-plans"
                                    className="inline-button"
                                  >
                                    <span>
                                      Limitations and Future Plans{" "}
                                      <FontAwesomeIcon
                                        icon={faTriangleExclamation}
                                      />
                                    </span>
                                  </a>
                                  .
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Padding paddingTop={this.sectionPadding}></Padding>
                    <div>
                      <div className="title-area">
                        <div className="title">About Us</div>
                      </div>
                      <div className="text-area">
                        <div className="section">
                          <div className="container">
                            <div className="columns">
                              <div className="column">
                                <p>
                                  We are a team comprising two PhD students with
                                  backgrounds in software development,
                                  industrial design, and research.
                                </p>
                                <div className="columns">
                                  <div className="column">
                                    <p>
                                      <b>Egemen Ertuğrul</b>{" "}
                                      <a href="https://www.linkedin.com/in/egemenertugrul/">
                                        <FontAwesomeIcon icon={faLinkedin} />
                                      </a>
                                      {"  "}is a creative engineer with a wide
                                      spectrum of projects under his belt. He is
                                      an advocate of open-source and accessible
                                      XR, and one of the founding members of{" "}
                                      <a href="https://xrturkiye.org/">
                                        XR Türkiye
                                      </a>
                                      .
                                    </p>
                                  </div>
                                  <div className="column">
                                    <p>
                                      <b>Hakan Yılmazer</b>{" "}
                                      <a href="https://www.linkedin.com/in/hakan-yilmazer-3aa7b69a/">
                                        <FontAwesomeIcon icon={faLinkedin} />
                                      </a>
                                      {"  "}is an all-around industrial designer
                                      with a passion for exploring the field of
                                      XR. He is highly skilled in 3D CAD, 3D
                                      printing, and electronics.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Padding paddingTop={this.sectionPadding}></Padding>
                    <div>
                      <div className="title-area">
                        <div className="title">Citation</div>
                      </div>
                      <div className="text-area">
                        <div className="section">
                          <div className="container">
                            <div className="columns">
                              <div className="column">
                                <p>
                                  If you use this content or software, please
                                  cite it as below.
                                </p>

                                <Tabs
                                  onSelect={(index) => {
                                    this.setState({
                                      tabIndex: index,
                                    });
                                  }}
                                >
                                  <TabList>
                                    <Tab>BibTeX</Tab>
                                    <Tab>APA</Tab>
                                  </TabList>
                                  <div>
                                    <TabPanel>
                                      <p className="tab-panel-content">
                                        <CopyBlock
                                          text={`@software{Ertugrul_Wisor_2023,
    author = {Ertugrul, Egemen and Yilmazer, Hakan and Yantac, Asim Evren and Coskun, Aykut},
    month = nov,
    title = {{Wisor: Open Source Wireless XR Headset}},
    url = {https://github.com/egemenertugrul/Wisor},
    year = {2023}
  }`}
                                          language={"text"}
                                          theme={github}
                                          showLineNumbers={false}
                                          wrapLongLines={true}
                                        />
                                      </p>
                                    </TabPanel>
                                    <TabPanel>
                                      <p className="tab-panel-content">
                                        <CopyBlock
                                          text={`Ertugrul, E., Yilmazer, H., Yantac, A. E., & Coskun, A. (2023). Wisor: Open Source Wireless XR Headset [Computer software]. https://github.com/egemenertugrul/Wisor`}
                                          language={"text"}
                                          showLineNumbers={false}
                                          wrapLongLines={true}
                                          theme={github}
                                        />
                                      </p>
                                    </TabPanel>
                                  </div>
                                </Tabs>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Slogan content={["Open-source.", "Built by you."]}></Slogan> */}
                  {/* <Slogan
                    content={["Wireless XR.", "No strings attached."]}
                  ></Slogan> */}
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="section content">
              <div className="container">
                <p>Born in:</p>
                <div className="columns is-centered">
                  <div className="column is-two-thirds-desktop is-centered">
                    <div
                      className="columns is-mobile is-vcentered"
                      style={{
                        justifyContent: "space-evenly",
                        // pointerEvents: "none",
                      }}
                    >
                      <div className="column is-3-tablet sponsor">
                        <a href="https://www.ku.edu.tr/">
                          <img
                            src="/assets/images/koc_logo.png"
                            alt=""
                            draggable="false"
                          />
                        </a>
                      </div>
                      <div className="column is-3-tablet sponsor">
                        <a href="https://karma.ku.edu.tr/">
                          <img
                            src="/assets/images/karma_logo.png"
                            alt=""
                            draggable="false"
                          />
                        </a>
                      </div>
                      <div className="column is-3-tablet sponsor">
                        <a href="https://kuar.ku.edu.tr/">
                          <img
                            src="/assets/images/kuar_logo.png"
                            alt=""
                            draggable="false"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

createRoot(document.getElementById("root")).render(
  <App hideLoader={hideLoader} showLoader={showLoader}></App>
);
