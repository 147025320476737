import * as React from "react";
import "./WelcomeHero.css"; // Import the CSS file
const AOS = require("aos");
import { ReactFitty } from "react-fitty";

interface WelcomeHeroProps {
  fill: number;
  top: React.RefObject<HTMLElement>;
}
export default class WelcomeHero extends React.Component<WelcomeHeroProps> {
  scrollToTop = () => {
    this.props.top?.current.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    return (
      // <div className="section">
      <div
        // data-aos="fade-up"
        // data-aos-offset="250"
        // data-aos-delay="0"
        className="hero blurred-hero"
        style={{
          position: "sticky",
          top: 0,
          // backdropFilter: this.props.fill == 1 ? "blur(0)" : "blur(4px)",
          // backgroundColor: this.props.fill == 1 ? "rgb(0,0,0)" : "rgba(0,0,0,0)",
        }}
      >
        <div
          className="hero-body"
          style={{
            padding: `${(1 - this.props.fill) * 10}rem`,
          }}
        >
          <div>
            <div onClick={this.scrollToTop} className="logo">
              <img
                style={{
                  // Math.max(80, Math.log2(newOpacity + 1) * 100)
                  width: `${(1 - this.props.fill) * 10}rem`,
                  // mixBlendMode: this.props.fill == 1 ? "difference" : "darken",
                }}
                src="./assets/wisor_mid.png"
              />
              <div>
                <h2
                  className="subtitle"
                  style={{
                    fontSize: "1em",
                    opacity: 1 - this.props.fill,
                    position: "absolute",
                    bottom: "2rem",
                    left: 0,
                    right: 0,
                    display: this.props.fill == 1 ? "none" : "inline-block",
                  }}
                >
                  Let's expand the horizons of XR.
                </h2>
                {/* <br /> */}
                {/* <ReactFitty
                  className="subtitle"
                  style={{
                    opacity: 1 - this.props.fill,
                    position: "absolute",
                    bottom: "2rem",
                    left: 0,
                    right: 0,
                    display: this.props.fill == 1 ? "none" : "inline-block",
                  }}
                >
                </ReactFitty> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      // </div>
    );
  }
}
