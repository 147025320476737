import * as React from "react";

interface PaddingProps {
  paddingTop?: number; // Define the prop type for paddingTop as number
}

function Padding({ paddingTop }: PaddingProps) {
  const divStyle: React.CSSProperties = {
    paddingTop: paddingTop ? `${paddingTop}rem` : "0", // Use the paddingTop prop if provided, or default to "0"
    pointerEvents: "none",
  };

  return <div style={divStyle}></div>;
}

export default Padding;
