import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faS, faR } from "@fortawesome/free-solid-svg-icons";

// Base InfoSub component
interface InfoSubProps {
  tooltipText: string;
  children: React.ReactNode;
}

const InfoSub: React.FC<InfoSubProps> = ({ children, tooltipText }) => (
  <sub
    style={{
      paddingLeft: "2px",
      paddingRight: "2px",
    }}
    title={tooltipText}
  >
    [{children}]
  </sub>
);

// StandaloneSub component
const StandaloneSub: React.FC = () => (
  <InfoSub tooltipText="Standalone Mode">S</InfoSub>
);

// RemoteSub component
const RemoteSub: React.FC = () => (
  <InfoSub tooltipText="Remote Mode">R</InfoSub>
);

const BothSub: React.FC = () => (
  <InfoSub tooltipText="Standalone & Remote Modes">S,R</InfoSub>
);

export { InfoSub, StandaloneSub, RemoteSub, BothSub };
