import * as React from "react";
import * as THREE from "three";

const target = new THREE.Vector3(0, 0, 0);
let im_mesh: THREE.Object3D;
const EquirectangularViewer = () => {
  const containerRef = React.useRef(null);
  let camera: THREE.PerspectiveCamera,
    scene: THREE.Scene,
    renderer: THREE.WebGLRenderer;
  let isUserInteracting = false,
    onPointerDownPointerX = 0,
    onPointerDownPointerY = 0,
    lon = 0,
    onMouseDownLon = 0,
    lat = 0,
    onMouseDownLat = 0,
    phi = 0,
    theta = 0;

  React.useEffect(() => {
    const container = containerRef.current;

    camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      1,
      1100
    );

    scene = new THREE.Scene();

    const eq_geometry = new THREE.SphereGeometry(500, 60, 40);
    eq_geometry.scale(-1, 1, 1);

    const eq_material = new THREE.MeshBasicMaterial({
      map: new THREE.TextureLoader().load(
        "/assets/images/technical_testing_virtual_reality_3.jpg"
      ),
    });

    const eq_mesh = new THREE.Mesh(eq_geometry, eq_material);

    scene.add(eq_mesh);

    // const im_geometry = new THREE.PlaneGeometry(10, 10*.75);
    // const im_tex = new THREE.TextureLoader().load(
    //     '/assets/images/sc_wisor_transparent_expanded.png'
    //   , (tex) => {

    //     const im_material = new THREE.MeshBasicMaterial({
    //         map: im_tex,
    //       });
    //         im_mesh = new THREE.Mesh(im_geometry, im_material);
    //         im_mesh.scale.set(1.0, im_tex.image.height / im_tex.image.width, 1.0);

    //         im_mesh.position.set(0,0,0)
    //         im_mesh.lookAt(camera.position);

    //         scene.add(im_mesh);
    //   });
    // var light = new THREE.PointLight( 0xffffff, 1, 0 );
    // light.position.set(1, 1, 100 );
    // scene.add(light)

    // camera.position.z = 5;

    renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    container.appendChild(renderer.domElement);

    // document.addEventListener('mousedown', onDocumentMouseDown, false);
    // document.addEventListener('mousemove', onDocumentMouseMove, false);
    // document.addEventListener('mouseup', onDocumentMouseUp, false);
    // document.addEventListener('wheel', onDocumentMouseWheel, false);

    // document.addEventListener('dragover', (event) => {
    //   event.preventDefault();
    //   event.dataTransfer.dropEffect = 'copy';
    // }, false);

    // document.addEventListener('dragenter', (event) => {
    //   document.body.style.opacity = 0.5;
    // }, false);

    // document.addEventListener('dragleave', (event) => {
    //   document.body.style.opacity = 1;
    // }, false);

    // document.addEventListener('drop', (event) => {
    //   event.preventDefault();
    //   const reader = new FileReader();
    //   reader.addEventListener('load', (event) => {
    //     material.map.image.src = event.target.result;
    //     material.map.needsUpdate = true;
    //   }, false);
    //   reader.readAsDataURL(event.dataTransfer.files[0]);
    // //   document.body.style.opacity = 1;
    // }, false);

    window.addEventListener("resize", onWindowResize, false);

    animate();

    return () => {
      // Cleanup event listeners or any other cleanup here
    };
  }, []);

  function onWindowResize() {
    const width = 1920; // window.innerWidth;
    const height = 1080; // window.innerHeight;
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
  }

  function onDocumentMouseDown(event: {
    preventDefault: () => void;
    clientX: number;
    clientY: number;
  }) {
    event.preventDefault();
    isUserInteracting = true;
    onPointerDownPointerX = event.clientX;
    onPointerDownPointerY = event.clientY;
    onMouseDownLon = lon;
    onMouseDownLat = lat;
  }

  function onDocumentMouseMove(event: { clientX: number; clientY: number }) {
    if (isUserInteracting === true) {
      lon = (onPointerDownPointerX - event.clientX) * 0.1 + onMouseDownLon;
      lat = (event.clientY - onPointerDownPointerY) * 0.1 + onMouseDownLat;
    }
  }

  function onDocumentMouseUp(event: any) {
    isUserInteracting = false;
  }

  function onDocumentMouseWheel(event: { deltaY: number }) {
    camera.fov += event.deltaY * 0.05;
    camera.updateProjectionMatrix();
  }

  function animate() {
    requestAnimationFrame(animate);
    update();
  }

  function update() {
    if (isUserInteracting === false) {
      lon += 0.1;
    }

    lat = Math.max(-85, Math.min(85, lat));
    phi = THREE.MathUtils.degToRad(90 - lat);
    theta = THREE.MathUtils.degToRad(lon);

    target.x = 500 * Math.sin(phi) * Math.cos(theta);
    target.y = 500 * Math.cos(phi);
    target.z = 500 * Math.sin(phi) * Math.sin(theta);
    const targetNorm = target.normalize().multiplyScalar(10);
    camera.lookAt(target);
    if (im_mesh != null) {
      im_mesh.position.set(targetNorm.x, targetNorm.y, targetNorm.z);
      im_mesh.lookAt(camera.position);
    }

    renderer.render(scene, camera);
  }

  return <div className="equicontainer" id="container" ref={containerRef} />;
};

export default EquirectangularViewer;
