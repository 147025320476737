import * as React from "react";
import * as ReactDOM from "react-dom";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { PLYLoader } from "three-stdlib";

import {
  AdditiveBlending,
  Box3,
  BoxHelper,
  BufferGeometry,
  BufferGeometryUtils,
  Color,
  DirectionalLight,
  Group,
  Matrix4,
  Mesh,
  MeshStandardMaterial,
  Object3D,
  Points,
  PointsMaterial,
  Quaternion,
  Vector3,
} from "three";

import { useLoader } from "@react-three/fiber";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { PCDLoader } from "three/examples/jsm/loaders/PCDLoader.js";

import { Stats, OrbitControls } from "@react-three/drei";
import Revealable from "./Reveal";

function ToPoints(obj: Object3D | Mesh, out?: Object3D) {
  if (out === undefined) {
    out = new Object3D();
  }
  const pointsMaterial = new PointsMaterial({
    color: 0xff0000,
    size: 0.003,
    // size: 2,
    blending: AdditiveBlending,
    transparent: true,
    opacity: 0.8,
    depthWrite: false,
    sizeAttenuation: true,
  });
  obj.children.forEach((o) => {
    // console.log(o)
    if (o instanceof Mesh) {
      const pointsMesh = new Points(o.geometry, pointsMaterial);
      out.add(pointsMesh);
    } else if (o instanceof Points) {
      o.updateMatrix();
      const points = new Group();
      points.matrixAutoUpdate = false;
      points.matrix.copy(o.matrix);
      points.matrixWorldNeedsUpdate = true;

      o.material = pointsMaterial;
      out.add(o);
    } else {
      o.updateMatrix();
      const points = new Group();
      points.matrixAutoUpdate = false;
      points.matrix.copy(o.matrix);
      points.matrixWorldNeedsUpdate = true;

      out.add(ToPoints(o, points));
    }
  });
  return out;
}

const Headset = React.forwardRef(function Headset() {
  const [isLoading, setIsLoading] = React.useState(true); // State to track loading status

  // const gltf = useLoader(GLTFLoader, "./assets/headset_optimized.gltf", (loader) => {
  //   // This callback will be called when the GLTF model is loaded

  //   setIsLoading(false);
  // }) as GLTF;
  const model = useLoader(PCDLoader, "./assets/headset.pcd", (loader) => {
    //   // This callback will be called when the GLTF model is loaded
    setIsLoading(false);
  });

  model.rotation.set(-Math.PI / 2, 0, 0);

  React.useEffect(() => {}, [isLoading]);

  const headset = new Revealable();
  headset.add(model);
  const points = new Revealable();
  points.add(ToPoints(headset));

  const { camera, mouse, size } = useThree();
  const objectRotations = [
    new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), Math.PI / 2),
    new Quaternion().setFromAxisAngle(new Vector3(-0.25, 0.5, 0), Math.PI / 4),
    new Quaternion().setFromAxisAngle(new Vector3(-1, 0, 0), Math.PI / 2),
    new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), (Math.PI / 2) * 3),
    new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), 0),
  ];

  let objectRotationsIndex = 0;

  // headset.setVisible(true);
  // headset.targetOpacity = 0.1;
  points.setVisible(true);

  const randomColor = new Color().setHSL(Math.random(), 1, 0.1);
  points.setColor(randomColor);
  const rot = objectRotations[objectRotationsIndex];
  headset.quaternion.set(rot.x, rot.y, rot.z, rot.w);
  points.quaternion.set(rot.x, rot.y, rot.z, rot.w);
  objectRotationsIndex++;

  let timeCounter = 0;

  useFrame((state, delta) => {
    const rot = objectRotations[objectRotationsIndex].normalize();

    const angleDiff = points.quaternion.angleTo(rot);

    headset.quaternion.slerp(rot, 0.0075);
    points.quaternion.slerp(rot, 0.0075);
    points.matrixWorldNeedsUpdate = true;

    if (angleDiff >= 0.1 && angleDiff < 1 && !points.isVisible) {
      // timeVal = 0;
      const randomColor = new Color().setHSL(Math.random(), 1, 0.1);
      points.setColor(randomColor);

      points.setVisible(true);
      // headset.setVisible(true);
      // headset.setColor(randomColor);
    }
    // else if (angleDiff <= 0.05)
    if (angleDiff < 0.05) {
      timeCounter += delta;
      if (timeCounter > 0.5) {
        points.setVisible(false);
        // headset.setVisible(false);
      }

      if (timeCounter > 3) {
        objectRotationsIndex =
          (objectRotationsIndex + 1) % objectRotations.length;
        timeCounter = 0;
      }
    }
  });

  return (
    <React.Suspense>
      {/* <primitive object={headset} position={[0, 0, 0]} children-0-castShadow /> */}
      <primitive object={points} position={[0, 0, 0]} />
    </React.Suspense>
  );
});

function Rig() {
  const { camera, mouse, size } = useThree();
  const moveCoeff = 50;

  const radius = 0.2; // boundingSphere.radius;
  let aspect = window.innerWidth / window.innerHeight;
  if (aspect > 1.0) {
    // if view is wider than it is tall, zoom to fit height
    camera.zoom = window.innerHeight / (radius * 2);
  } else {
    // if view is taller than it is wide, zoom to fit width
    camera.zoom = window.innerWidth / (radius * 2);
  }
  camera.updateProjectionMatrix();

  const mouseVec = new Vector3();
  const cameraBase = new Object3D();
  const root = new Object3D();

  root.add(cameraBase);
  cameraBase.add(camera);
  // cameraBase.rotateOnWorldAxis(new Vector3(0, 1, 0), Math.PI / 2);
  return useFrame((state, delta) => {
    cameraBase.position.lerp(
      mouseVec.set(mouse.x / moveCoeff, -mouse.y / moveCoeff, 0),
      0.05
    );
    camera.lookAt(camera.position.x, camera.position.y, 0);
  });
}

class HeadsetComponent extends React.Component {
  private headset: React.RefObject<Object3D>;
  private light: DirectionalLight;
  // private lightIntensity: number;
  // private setLightIntensity: React.Dispatch<React.SetStateAction<number>>;

  constructor(props: {}) {
    super(props);
    this.headset = React.createRef<Object3D>();
    // [this.lightIntensity, this.setLightIntensity] = React.useState<number>(0.1);

    this.light = new DirectionalLight(0xffffff); // Create the directional light
    this.light.position.set(3.3, 1.0, -4.4); // Set its position

    this.state = {
      isLightEnabled: true, // Initialize the light as enabled
    };
  }

  // enableLight = () => {
  //   if (!this.light.parent) {
  //     this.props.scene.add(this.light);
  //     this.setState({ isLightEnabled: true });
  //   }
  // };

  // // Function to disable the light
  // disableLight = () => {
  //   if (this.light.parent) {
  //     this.props.scene.remove(this.light);
  //     this.setState({ isLightEnabled: false });
  //   }
  // };

  shouldComponentUpdate(
    nextProps: Readonly<{}>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    return false;
  }

  render() {
    return (
      <Canvas
        orthographic
        camera={{
          position: new Vector3(0, -0.03, -0.4),
          near: 0.0001,
          far: 50000,
        }}
      >
        <color attach="background" args={[0, 0, 0]} />
        <directionalLight
          position={[3.3, 1.0, -4.4]}
          castShadow={false}
          intensity={1}
        />
        <Headset ref={this.headset} />
        <Rig />
      </Canvas>
    );
  }
}
export default HeadsetComponent;
