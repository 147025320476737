import { useFrame } from "@react-three/fiber";
import { Color, Mesh, Object3D, Points } from "three";
import { lerp } from "three/src/math/MathUtils";

export default class Revealable extends Object3D {
  isVisible = false;
  beginOpacity = 0;
  endOpacity = 0.8;
  currentOpacity = this.beginOpacity;
  targetOpacity = this.endOpacity;
  lerpStartingPoint = this.currentOpacity;
  t = 0;
  constructor() {
    super();
    this.applyOpacity();

    useFrame((state, delta) => {
      if (this.currentOpacity == this.targetOpacity) return;

      if (this.t >= 1) {
        this.currentOpacity = this.targetOpacity;
        this.lerpStartingPoint = this.currentOpacity;
        this.t = 0;
        this.applyOpacity();
      }
      this.currentOpacity = lerp(
        this.lerpStartingPoint,
        this.targetOpacity,
        this.t
      );
      this.t += 0.833 * delta;
      this.applyOpacity();
    });
  }

  applyOpacity = () => {
    this.traverse((o) => {
      if (o instanceof Mesh || o instanceof Points) {
        o.material.opacity = this.currentOpacity;
        o.material.transparent = true;
      }
    });
  };

  setVisible = (state: boolean) => {
    if (this.isVisible == state) return;
    this.isVisible = state;
    if (this.isVisible) this.targetOpacity = this.endOpacity;
    else this.targetOpacity = this.beginOpacity;
    this.t = 0;
    this.lerpStartingPoint = this.currentOpacity;
  };

  setColor = (color: Color) => {
    this.traverse((o) => {
      if (o instanceof Mesh || o instanceof Points) {
        o.material.color = color;
      }
    });
  };
}
